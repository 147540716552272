
<template>
  <div>
    <lineFieldsArray
      :fieldAttrRow="{}"
      :fields="fields"
      :templateContent="{}"
      :value="pairArray"
      @line-field-input="checkInput(...arguments)"
      :headerTitle="false"
      :addable="false"
      :linePrefix="getFieldId(fieldAttributes.fieldAttrInput, result, field)"
    ></lineFieldsArray>
    <input
      type="hidden"
      :value="JSON.stringify(this.parsedValue)"
      :name="field"
    />
  </div>
</template>
<script>
import lineFieldsArray from "@/commonComponents/lineFieldsArray.vue";
import lineButtons from "@/cfg/lineButtons.json";
import { getFieldId } from "@/js/helper.js";
export default {
  props: ["result", "fieldAttributes", "field", "value"],
  components: { lineFieldsArray },
  data() {
    return {};
  },

  computed: {
    fields() {
      let fields = {
        value: {
          type: "text",

          name: this.fieldAttributes.name,
          fieldAttrInput: this.fieldAttributes.fieldAttrInput,
        },

        remove: lineButtons.remove,
        add: lineButtons.add,
      };
      return fields;
    },
    parsedValue() {
      return JSON.parse(this.value);
    },
    pairArray: {
      get() {
        let pairArray = [];

        if (
          typeof this.parsedValue != "undefined" &&
          Object.keys(this.parsedValue).length != 0
        ) {
          console.log(this.parsedValue);
          this.parsedValue.forEach((value) => {
            pairArray.push({ value: value });
          });
        } else {
          pairArray = [{ value: "" }];
        }
        return pairArray;
      },
      set(newValue) {
        this.$emit("input", JSON.stringify(newValue));
      },
    },
  },
  methods: {
    getFieldId: getFieldId,
    checkInput(value) {
      console.log(value);
      let sentValue = [];
      let update = true;
      // let caller = this;
      value.forEach(function (info) {
        if (typeof sentValue[info.value] != "undefined") {
          update = false;
          return;
        }
        sentValue.push(info.value);
      });
      if (update) {
        //this.$emit("input", sentValue);
        this.$emit("input", JSON.stringify(sentValue));
      }
    },
  },
};
</script>